<template>
  <v-container>
                  <v-hover v-slot:default="{ hover } ">
                <v-card @click="showOverlay" elevation=2 class="component1" link>
                     <VueGlow :intense="hover ? true : false" :color="asset.color">
                    <v-card-title>
                        <v-row dense>
                            <v-col align="center" cols=12 lg=12>
                                <cryptoicon v-if="asset.icon" :symbol="asset.icon" size="72" />
                                <v-avatar size=80 v-if="asset.logo"><v-img
                                    :src="require(`@/assets/logos/${asset.logo}`)" />
                                </v-avatar> 
                            </v-col>
                            <v-col align="center" class="headline" lg=12>
                                {{asset.name}}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row dense>
                            <v-col align="center" cols=12 lg=12>
                                <hr>
                            </v-col>
                            <v-col class="subtitle-1 secondary--text" cols=12 lg=12>
                                {{asset.type}}
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col lg=6>
                                Marketcap
                            </v-col>
                            <v-col class="text-lg-right" cols=6 lg=6>
                                ${{asset.financials.marketcap}}
                            </v-col>
                            <v-col cols=6 lg=6>
                                Price
                            </v-col>
                            <v-col class="text-lg-right" cols=6 lg=6>
                                ${{asset.financials.price}}
                            </v-col>
                            <v-col cols=6 lg=6>
                                Change
                            </v-col>
                            <v-col class="primary--text text-lg-right" cols=6 lg=6>
                                {{asset.financials.change}}%
                            </v-col>
                        </v-row>
                    </v-card-text>
                                                </VueGlow>

                </v-card>
                            </v-hover>

                <v-overlay
                :value="overlay"
                opacity="0.6"
                >
                            <VueGlow :color="asset.color">
                    <v-card elevation=7 max-width=400px class="component1">
                                                    <v-form>

                        <v-card-title>
                            <v-row no-gutters>
                                <v-col lg=2>
                                </v-col>
                                <v-col align="center" class="headline" lg=8>
                                                                         <cryptoicon v-if="asset.icon" :symbol="asset.icon" size="24" />
                                <v-avatar v-if="asset.logo"><v-img
                                    max-width="45px" contain :src="require(`@/assets/logos/${asset.logo}`)" />                                </v-avatar>

                                     Trade {{asset.name}}
                                </v-col>
                                <v-col lg=2 align="right">
                                    <v-btn @click="closeOverlay" icon depressed class="component1 headline"> <v-icon>mdi-close</v-icon></v-btn>
                                </v-col>
                                <v-col class="pt-2" lg=12>
                                    <hr>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col align="center" class="pt-12" lg=12>
                                    <v-img src="../../assets/argonAvatar.png" max-width="50px"/>
                                </v-col>
                                <v-col align="center" lg=12>
                                    In order to buy or sell {{asset.title}} enter the amount below
                                </v-col>
                                <v-col align="center" lg=12>
                                    <v-tabs fixed-tabs background-color="component1">
                                        <v-tab @click="setBuy">
                                            Buy
                                        </v-tab>
                                        <v-tab @click="setSell">
                                            Sell
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>
                            <v-row dense align="center">
                                <v-col align="right" lg=12 class="secondary--text">
                                    
                                </v-col>
                                <v-col lg=12>
                                        <v-text-field
                                        type="number"
                                        outlined
                                        :label='currentTab'
                                        hint="Enter an amount"
                                        :suffix="pair0balance"
>
                                            Amount
                                            
                                        </v-text-field>
                                </v-col>
                                <v-col align="left" lg=12 class="secondary--text">
                                    1.24 {{pair0}} per {{pair1}}
                                </v-col>
                                <v-col lg=12>
                                        <v-text-field
                                        type="number"
                                        
                                        label="For"
                                        disabled
                                        hint="Amount you will receive"
                                        :suffix="pair1balance">
                                            Amount
                                        </v-text-field>
                                </v-col>

                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-row>
                                <v-col align="center" lg=12>
                            <v-btn v-if="pair0 == 'USD'" class="profit black--text" x-large width="90%" elevation=5> Buy </v-btn>
                            <v-btn v-if="pair0 != 'USD'" class="error" width="90%" x-large elevation=5> Sell</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>

                        </v-form>

                    </v-card>
                            </VueGlow>
                </v-overlay>
                

    </v-container>   
</template>  
 
<script>
import VueGlow from 'vue-glow';

export default {

    data() {
        return {
            overlay: false,
            pair0: 'USD',
            pair1: this.$props.asset.name,
            currentTab: 'Buy'
        }
    },

    components: {
        VueGlow
    },

    props: ['asset'],

    computed: {
        pair0balance() {
            return "Balance: 23.45 " + this.$data.pair0
        },
        pair1balance() {
            return "Balance: 1.5 " + this.$data.pair1
        }
    },

    methods: {
        showOverlay() {
            this.overlay = true
        },
        closeOverlay() {
            this.overlay = false
            this.setBuy()
        },
        
        swapPair() {
            console.log("Swapping")
            let temp = this.pair0
            this.pair0 = this.pair1
            this.pair1 = temp
        },

        setBuy() {
            this.pair0 = 'USD'
            this.pair1 = this.$props.asset.name
            this.currentTab = 'Buy'
        },
        setSell() {
            this.pair0 = this.$props.asset.name
            this.pair1 = 'USD'
            this.currentTab = 'Sell'
        }
    }
    

}
</script>
