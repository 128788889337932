<template>
    <v-container>
        <v-row>
            <v-col class="headline" cols=12 lg=12>
                Cryptocurrencies
            </v-col>
            <v-col v-for="asset in cryptocurrencies" :key="asset.name" cols=12 md=4 lg=3 xl=2>
                <AssetCard :asset="asset" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="headline" cols=12 lg=12>
                Mirrored Stocks
            </v-col>
            <v-col v-for="asset in synthetic_stocks" :key="asset.name" cols=12 md=4 lg=3 xl=2>
                <AssetCard :asset="asset" />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="headline" cols=12 lg=12>
                Mirrored Commodities
            </v-col>
            <v-col v-for="asset in synthetic_commodities" :key="asset.name" cols=12 md=4 lg=3 xl=2>
                <AssetCard :asset="asset" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import AssetCard from '../../components/Base/AssetCard'

export default {
    components: {
        AssetCard
    },

    data() {
        return {
            cryptocurrencies: [
                {
                    icon: "BTC",
                    name: "wBTC",
                    title: "Bitcoin",
                    color: "orange",
                    type: "Cryptocurrency",
                    financials: {
                        marketcap: "232bn",
                        price: "21600",
                        change: "2.6"
                    }
                },
                {
                    icon: "ETH",
                    name: "ETH",
                    title: "Ethereum",
                    color: "violet",
                    type: "Cryptocurrency",
                    financials: {
                        marketcap: "76bn",
                        price: "670",
                        change: "2.6"
                    }
                },
                {
                    icon: "MKR",
                    name: "MKR",
                    title: "MakerDAO",
                    color: "lightgreen",
                    type: "Cryptocurrency",
                    financials: {
                        marketcap: "76bn",
                        price: "670",
                        change: "2.6"
                    }
                }
            ],
            synthetic_stocks: [
                {
                    logo: "netflix.png",
                    name: "mNFLX",
                    title: "Mirrored Netflix",
                    color: "darkred",
                    type: "Synthetic Stock",
                    financials: {
                        marketcap: "232bn",
                        price: "156",
                        change: "2.6"
                    }
                },
                {
                    logo: "tesla.png",
                    name: "mTSLA",
                    title: "Mirrored Tesla",
                    color: "red",
                    type: "Synthetic Stock",
                    financials: {
                        marketcap: "232bn",
                        price: "156",
                        change: "2.6"
                    }
                },
                {
                    logo: "microsoft.png",
                    name: "mMSFT",
                    title: "Mirrored Microsoft",
                    color: "white",
                    type: "Synthetic Stock",
                    financials: {
                        marketcap: "232bn",
                        price: "156",
                        change: "2.6"
                    }
                }],
            synthetic_commodities: [
                {
                    logo: "oil.png",
                    name: "mUSO",
                    title: "Mirrored Oil",
                    color: "white",
                    type: "Synthetic Commodity",
                    financials: {
                        marketcap: "232bn",
                        price: "156",
                        change: "2.6"
                    }
                }
            ]
            
        }
    }
}

</script>